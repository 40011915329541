import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import SimplePage from "../components/simple-page";
import Paragraph from "../components/typography/paragraph";

const LegalPage = () => {
  const data = useStaticQuery(graphql`
    query {
      legal: file(relativePath: { eq: "legal.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      markdownRemark(frontmatter: { path: { eq: "terms-of-service" } }) {
        html
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Terms of Service" />
      <SimplePage
        title="Terms of Service"
        description="So we don't get sued"
        fluid={data.legal.childImageSharp.fluid}
      >
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </SimplePage>
    </Layout>
  );
};

export default LegalPage;
